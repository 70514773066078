export default function role(role) {
  if (role === "admin") {
    return "Руководитель"
  } else if (role === "manager") {
    return "Менеджер"
  } else if (role === "fitter") {
    return "Монтажник"
  } else if (role === "stockman") {
    return "Кладовщик"
  } else {
    return role
  }
}
