<template>
  <div class="employees">
    <div class="page_header">
      <h1>Сотрудники</h1>
      <el-button type="primary" size="mini" @click="create" icon="el-icon-plus">Добавить</el-button>
    </div>
    <div class="page_content" v-loading="loading">
      <div class="list" v-if="employees">
        <table class="table">
          <thead>
          <tr>
            <th>#</th>
            <th>ФИО</th>
            <th>Телефон</th>
            <th>Должность</th>
            <th>Последняя активность</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(employee, idx) in employees" :key="employee.id">
            <td>{{ ++idx }}</td>
            <td>
              {{ employee.fio_full }}
            </td>
            <td>{{ employee.user.formatted_phone }}</td>
            <td>
              {{ employee.user.role | role }}
            </td>
            <td>
              <span v-if="employee.user.last_activity">{{ moment(employee.user.last_activity).calendar() }}</span>
            </td>
            <td>
              <el-button type="primary" size="mini" icon="el-icon-edit" @click="openDrawer(employee)">
                Изменить
              </el-button>
              <el-popconfirm
                  @confirm="remove(employee.id)"
                  confirm-button-text='Да'
                  cancel-button-text='Нет'
                  title="Вы действительно хотите удалить?">
                <el-button
                    slot="reference"
                    icon="el-icon-delete"
                    size="mini" type="danger"
                    style="margin-left: 5px;"></el-button>
              </el-popconfirm>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p>Нет данных для отображения</p>
      </div>
    </div>
    <el-drawer
        :visible.sync="drawerActive"
        direction="rtl"
        size="30%"
        custom-class="drawer_box"
        :before-close="closeDrawer"
        :destroy-on-close="true"
        :show-close="false"
        :wrapperClosable="false"
        :withHeader="false"
        :append-to-body="true"
        class="settings_drawer"
    >
      <div class="drawer_header">
        <h3 class="title font-ui">Сотрудник</h3>
        <button class="close_btn" @click="closeDrawer">
          <i class="el-icon-close"></i>
        </button>
      </div>

      <div class="drawer_content" v-if="formData" v-loading="submitting">
        <el-form :model="formData" :rules="formRules" ref="formData" class="form"
                 @submit.native.prevent="formDataAction('formData')">
          <el-row :gutter="10">
            <el-col :span="8" :offset="0">
              <el-form-item label="Фамилия" prop="surname">
                <el-input v-model="formData.surname" size="mini"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" :offset="0">
              <el-form-item label="Имя" prop="name">
                <el-input v-model="formData.name" size="mini"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" :offset="0">
              <el-form-item label="Отчество" prop="patronymic">
                <el-input v-model="formData.patronymic" size="mini"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <el-col :span="12" :offset="0">
              <el-form-item label="Телефон (используется для входа)" prop="user.phone">
                <el-input
                    v-model="formData.user.phone"
                    size="mini"
                    type="tel"
                    v-facade="'+7 (###) ###-####'"
                    placeholder="+7 (___) ___-____"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="0">
              <el-form-item label="Роль сотрудника" prop="role">
                <el-select
                    v-model="formData.user.role"
                    placeholder="Роль сотрудника"
                    size="mini">
                  <el-option
                      v-for="role in userRoles"
                      :key="role.value"
                      :label="role.title"
                      :value="role.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" :offset="0">
              <el-form-item label="Офис сотрудника" prop="office">
                <el-select
                    v-model="formData.office_id"
                    placeholder="Офис сотрудника"
                    size="mini">
                  <el-option label="Офис не выбран" :value="null"></el-option>
                  <el-option
                      v-for="office in offices"
                      :loading="officesLoading"
                      :key="office.id"
                      :label="office.title"
                      :value="office.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item>
            <el-button
                type="primary"
                size="mini"
                @click="formDataAction('formData')">Сохранить
            </el-button>
            <el-button size="mini" @click="closeDrawer">Отменить</el-button>
          </el-form-item>

        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>

import {mapState} from "vuex"
import role from "@/filters/role"

import {actionTypes as employeeActions} from "@/store/modules/employees"
import {actionTypes as officeActions} from "@/store/modules/offices"

export default {
  name: "Employees",
  data() {
    let validatePhoneLength = (rule, value, callback) => {
      if (value.length < 17) {
        callback(new Error('Введите номер телефона полностью'))
      } else {
        callback()
      }
    }
    let validatePhoneFormat = (rule, value, callback) => {
      if (value[4] != 9) {
        callback(new Error('Введите правильный номер телефона'))
      } else {
        callback()
      }
    }
    return {
      drawerActive: false,
      formData: null,
      userRoles: [
        {title: "Руководитель", value: "admin"},
        {title: "Менеджер", value: "manager"},
        {title: "Монтажник", value: "fitter"},
        {title: "Кладовщик", value: "stockman"},
      ],
      formRules: {
        name: [
          {required: true, message: 'Введите имя', trigger: 'blur'}
        ],
        surname: [
          {required: true, message: 'Введите фамилию', trigger: 'blur'}
        ],
        patronymic: [
          {required: true, message: 'Введите отчество', trigger: 'blur'}
        ],
        'user.phone': [
          {required: true, message: 'Введите номер телефона', trigger: 'blur'},
          {validator: validatePhoneFormat, trigger: 'blue'},
          {validator: validatePhoneLength, trigger: 'blur'},
        ],
      }
    }
  },
  computed: {
    ...mapState({
      employees: (state) => state.employees.list,
      loading: (state) => state.employees.loading,
      submitting: (state) => state.employees.submitting,
      errors: (state) => state.employees.errors,
      offices: (state) => state.offices.list,
      officesLoading: (state) => state.offices.loading,
    })
  },
  methods: {
    create() {
      this.drawerActive = true
      this.formData = {
        surname: '',
        name: '',
        patronymic: '',
        user: {
          phone: '',
          role: 'manager',
        },
        office_id: null
      }
    },
    openDrawer(employee) {
      this.formData = _.cloneDeep(employee)
      this.drawerActive = true
    },
    closeDrawer() {
      this.drawerActive = false
      this.formData = null
    },
    formDataAction(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch(employeeActions.save, this.formData).then(() => {
            this.loadEmployees()
            this.drawerActive = false
            this.formData = null
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    remove(id){
      this.$store.dispatch(employeeActions.remove, id).then(() => {
        this.loadEmployees()
      })
    },
    loadEmployees() {
      this.$store.dispatch(employeeActions.get)
    },
    loadOffices() {
      this.$store.dispatch(officeActions.get)
    },
  },
  filters: {
    role,
  },
  watch: {},
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }
}
</script>
